//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'

export default {
    props: {
        section: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            activeIndex: 0
        }
    },
    computed: {
        items: function () {
            return get(this.section, 'fields.items', [])
        }
    },
    methods: {
        get,
        switchTab ({ index }) {
            this.activeIndex = index
        }
    }
}

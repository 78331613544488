import { render, staticRenderFns } from "./Products.vue?vue&type=template&id=2b8ae897&"
import script from "./Products.vue?vue&type=script&lang=js&"
export * from "./Products.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSectionHeader: require('/var/www/html/spa-website/components/home/SectionHeader.vue').default,PageNavbar: require('/var/www/html/spa-website/components/PageNavbar.vue').default,YoutubeVideo: require('/var/www/html/spa-website/components/YoutubeVideo.vue').default,ContentfulImage: require('/var/www/html/spa-website/components/contentful/Image.vue').default,IconPrev: require('/var/www/html/spa-website/components/icons/IconPrev.vue').default,DynamicLink: require('/var/www/html/spa-website/components/DynamicLink.vue').default,IconNext: require('/var/www/html/spa-website/components/icons/IconNext.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { filter, get, isEmpty, size } from 'lodash'
import Scrollbar from 'smooth-scrollbar'

export default {
    props: {
        sections: {
            type: [Array, Object],
            required: true
        },
        titleField: {
            type: String,
            default: 'anchorLabel'
        },
        activeIndex: {
            type: Number,
            default: -1
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        pinnedSections: function () {
            return filter(this.sections, this.isPinned)
        }
    },
    methods: {
        get,
        size,
        isPinned: function (section) {
            // Skip draft and section with empty `titleField`
            return !isEmpty(get(section, 'fields')) && !isEmpty(get(section, `fields.${this.titleField}`))
        },
        onClick (e) {
            this.$emit('item-click', e)
        }
    },
    mounted () {
        Scrollbar.init(this.$refs.container, {
            alwaysShowTracks: true
        })
    }
}
